
import Transation from "#/views/Transation";

const constantRoutes = [
    {
        path: '/auth',
        name: 'auth',
        component: () => import("../layouts/AuthLayout.vue"),
        meta: {
            title: '登录布局'
        },
        children: [
            {
                path: 'login',
                name: 'auth.login',
                component: () => import("../views/auth/login.vue"),
                meta: {
                    title: '登录页'
                },
            }
        ]
    },
    {
        path: '/admin',
        name: 'admin',
        component: () => import("../layouts/AdminLayout.vue"),
        // component: (resolve) => require(["../layouts/AdminLayout.vue"], resolve),
        meta: {
            title: '后台首页布局'
        },
        redirect: '/admin/dashboard',
        children: [
            {
                path: "/admin/dashboard",
                name: "admin.dashborad",
                component: () => import("../views/admin/dashboard/dashboard.vue")
            }
        ]
    },
    {
        path: '/Transation',
        name: 'transation',
        component: Transation,
        meta: {
            title: '过渡路由'
        }
    },

]
export default constantRoutes