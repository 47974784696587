function loadView(view) {
    return (resolve) => require([`#/views${view}`], resolve)
}

function generateAsyncRoutes(routes) {
    const res = []
    // 定义路由中需要的自定名
    const keys = ['name', 'path', 'children', 'meta']
    // 遍历路由数组去重组可用的路由

    routes.forEach(item => {
        const newItem = {};
        if (item.component) {
            newItem.component = () => import(/* webpackChunkName: "group-foo" */ `#/views${item.component}`)
        }
        //去掉路由最前面的/
        // newItem.path = item.path.replace(/^\//, "")
        //子组件中的path把父组件中的path去掉
        for (const key in item) {
            if (keys.includes(key)) {
                newItem[key] = item[key]
            }
        }
        // 若遍历的当前路由存在子路由，需要对子路由进行递归遍历
        if (newItem.children && newItem.children.length > 0) {
            newItem.children = generateAsyncRoutes(item.children)
        } else {
            delete newItem.children
        }
        res.push(newItem)
    })
    // 返回处理好且可用的路由数组
    // console.log("=========返回处理好且可用的路由数组=========")
    return res
}

export default generateAsyncRoutes;