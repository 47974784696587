import Vue from "vue";
import Vuex from "vuex";
import axios from "../utils/axios";

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    errors: {},
    role: null,
    theme: "dark",
    //当前角色菜单
    roleMenus: [],
    isLogin: false,
    //全量菜单
    fullMenus: [],
    isInstalled: false,
    //页面是否loading
    spin: false,
  },
  getters: {
    errors: (state) => (name) => {
      return state.errors[name] && state.errors[name][0];
    },
    roleMenus: (state) => {
      return state.roleMenus;
    },
    role: (state) => {
      return state.role;
    },
    theme: (state) => {
      return state.theme;
    },
    spin: (state) => {
      return state.spin;
    },
  },
  mutations: {
    errors(state, errors = {}) {
      state.errors = errors;
    },
    roleMenus(state, roleMenus = []) {
      state.roleMenus = roleMenus;
    },
    role(state, role = "") {
      state.role = role;
    },
    theme(state, theme = "dark") {
      state.theme = theme;
    },
    isInstalled(state, isInstalled = false) {
      state.isInstalled = isInstalled;
    },
    spin(state, spin = false) {
      state.spin = spin;
    },
  },
  actions: {
    errors(context, errors = {}) {
      context.commit("errors", errors);
    },
    role(context, role = "") {
      context.commit("role", role);
    },
    async getInfo(context, info = {}) {
      await axios.post("admin/user/info").then((res) => {
        context.commit("role", res.role);
        context.commit("roleMenus", res.roleMenus);
        context.commit("isInstalled", res.isInstalled);
      });
    },
    spin(context, spin) {
      context.commit("spin", spin);
    },
  },
});
