import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import constantRoutes from "../config/constantRoutes";
// import routes from "./routes"
import store from '../store'
import generateAsyncRoutes from '../config/asyncRouters'
import NotFound from "#/views/NotFound";

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}
const createRouter = () =>
    new VueRouter({
        mode: 'history',
        sensitive: true,
        routes: constantRoutes
    })

const router = createRouter()

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher
}


router.onReady(() => {
    console.log('onready......')

}, (e) => {
    console.log(e)
})
router.beforeEach(async (to, from, next) => {
    const isLogin = !!localStorage.getItem('token')
    NProgress.start();
    //如果已经登录则执行下一步
    //如果未登录跳转到登录页/auth/login
    if (isLogin && to.path === '/auth/login') {
        next({name: "admin"})
        return;
    }
    if (!isLogin && to.path !== '/auth/login') {
        next({name: "auth.login"})
        return;
    }
    //当store.state.roleMenus.length===0时，分3种情况
    if (isLogin && store.state.roleMenus.length === 0) {
        console.log("======== 重新獲取 =========")
        await store.dispatch('getInfo').then(() => {
            const addRoutes = generateAsyncRoutes(store.state.roleMenus)
            addRoutes.forEach(item => {
                router.addRoute("admin", item)
            })
        }).then(() => {
            //当用户强刷页面后，重新定位到to.path路由，注意这个时候有可能找不到页面，跳转到404页面
            //应该将404页面在此之后添加到路由中
            console.log("下一个路由", to.path)
            router.addRoute({
                path: '/:pathMatch(.*)*',
                name: 'notfound',
                component: NotFound
            })
            next({path: to.path})
            return;
        })
        next({path: to.path})
    }
    next();
})

router.afterEach(() => {
    NProgress.done();
})

export default router