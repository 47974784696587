<template>
  <div>
    <a-spin :spinning="spin" size="large">
      <router-view />
    </a-spin>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  route: false,
  computed: {
    ...mapGetters(['spin'])
  }
}
</script>

<style scoped>

</style>
